import axios from "axios";
import React from "react";
import { useReducer } from "react";
import { has } from "lodash";

const IPV4 = "192.168.1.181";
export const API_URL: string =
  "https://1x2be1smjf.execute-api.eu-west-1.amazonaws.com/old";
export const LOCAL_API_URL: string = `http://${IPV4}:5000/old`;

const getErrorMessage = (error: any) => {
  return has(error, "response.data.message")
    ? error.response.data.message
    : "SOMETHING WENT WRONG";
};

type tData = {
  email: string;
  username: string;
  points: number;
  awards: string;
};

type tErrors = Record<keyof tData, string>;

interface iState {
  data: tData;
  errors: tErrors;
  loading: boolean;
}

type tAction =
  | { type: "reset" }
  | { type: "set errors"; errors: Partial<tErrors> }
  | { type: "loading"; loading: boolean }
  | { type: "set data"; key: keyof tData; value: any };

const initialState: iState = {
  data: { email: "", username: "", points: 0, awards: "" },
  errors: { email: "", username: "", points: "", awards: "" },
  loading: false,
};

const reducer = (state: iState, action: tAction): iState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "set errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "set data": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    case "loading": {
      const { loading } = action;
      return { ...state, loading };
    }
    default: {
      return { ...state };
    }
  }
};

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data: { username, email, points },
    errors,
    loading,
  } = state;

  const onChange =
    (key: keyof tData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = e;
      dispatch({ type: "set data", key, value });
    };

  const setLoading = (loading: boolean) =>
    dispatch({ type: "loading", loading });

  const submit = () => {
    if (!email && !username) {
      return alert("Set username or email");
    }
    if (points <= 0) {
      return alert("Set valid points");
    }

    setLoading(true);
    axios.defaults.baseURL = API_URL;
    // axios.defaults.baseURL = LOCAL_API_URL;
    axios
      .put("/managers/give-points", { email, username, points })
      .then((res) => {
        alert("DONE");
      })
      .catch((err) => {
        console.log("err.response.data", err.response.data);
        alert(getErrorMessage(err));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: `rgba(0, 0, 0, 0.6`,
          }}
        ></div>
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <p>Email</p>
          <input value={email} onChange={onChange("email")} />
          {errors["email"] && <p style={{ color: "red" }}>Set email</p>}
        </div>
        <div>
          <p>Username</p>
          <input value={username} onChange={onChange("username")} />
          {errors["username"] && <p style={{ color: "red" }}>Set username</p>}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <p>Points</p>
          <input
            type="number"
            min={0}
            value={points}
            onChange={onChange("points")}
          />
        </div>
      </div>
      <div>
        <button onClick={submit}>
          <p>SUBMIT</p>
        </button>
      </div>
    </div>
  );
};

export default App;
